import Router from 'next/router';
import { isEmpty, isNil } from 'ramda';
import { ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { auditTime, debounceTime, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { getSupplierUrl } from '@bridebook/toolbox';
import gazetteer, { Gazetteer } from '@bridebook/toolbox/src/gazetteer';
import type { IUISupplier } from '@bridebook/toolbox/src/types';
import { getLocationName } from 'lib/analytics-utils';
import { getIsCollaborator } from 'lib/analytics-utils/get-is-collaborator';
import { getIsMobile } from 'lib/app/selectors';
import { WebAnalyticsContext } from 'lib/bbcommon/utils/bridebook-analytics';
import { env } from 'lib/env';
import { getCountryCode } from 'lib/i18n/selectors';
import {
  IChangedSearchCategoryAnalytics,
  IChangedSearchLocationAnalytics,
  IChangedSearchLocationAnalyticsDebounced,
  IClickedOnFilteredSearchTileAnalytics,
  IFetchSearchSuccessAction,
  IFetchSearchSuccessAnalytics,
  ISearchTriggerClickedSuppliersPageAnalytics,
  ISetSearchLocationAutocompleteText,
  SearchActionTypes,
} from 'lib/search/action-types';
import {
  changedSearchLocationAnalytics,
  changedSearchLocationAnalyticsDebounced,
  fetchedSearchDataAnalytics,
} from 'lib/search/actions';
import { getIdentityPropsKey } from 'lib/search/utils/get-identity-props-key';
import mapSupplierListToAnalytics from 'lib/search/utils/map-suppliers/map-supplier-list-to-analytics';
import {
  getWeddingDaysFilterFromResponse,
  getWeddingSeasonFilterFromMonths,
} from 'lib/search/utils/wedding-estimate-price-filter-helpers';
import type {
  Action,
  IApplicationState,
  IEpicDeps,
  ILoadedSearchResultsEventProps,
  ILoadedSearchResultsPropsFromSearchResponse,
  IResetSearchFiltersEventProps,
  ISearchState,
  IToggledSearchFiltersEventProps,
  IUsedSearchFiltersEventProps,
  IUsedSortByEventProps,
  TSearchResponse,
} from 'lib/types';
import { ProtectedUrls, PublicUrls } from 'lib/url-helper';
import {
  IFetchVenuerexSuppliersSuccessAction,
  VenuerexActionTypes,
} from 'lib/venuerex/action-types';
import { getVenueBooked } from 'lib/weddings/selectors';
import formatQuizFilters from '../../components/quiz/format-quiz-filters';
import { ISearchFiltersPropertiesGeneric } from './types';
import {
  priceFilterSeasonsArray,
  PriceFilterWeekDay,
  priceFilterWeekDayMapping,
  priceFilterWeekDaysArray,
} from './utils/wedding-estimate-price-filter-helpers';
import {Season} from "@bridebook/models/source/models/Weddings.types";
import {mapMonthToSeason} from "lib/utils";

const DEFAULT_DEBOUNCE_TIME = 5000;

// do not change strings, it will affect analytics
export enum MapToggleStateAnalytics {
  MapView = 'map',
  ListView = 'list',
}

// do not change string, it will affect analytics
export const presetsSection = 'presetsHeader';

const getSearchBarPropertiesGeneric = (
  getState: () => IApplicationState,
  type: string | boolean = '',
  openState: any = null,
  method: string | null = '',
  activeTab: string = '',
) => {
  const {
    app: {
      previousPath,
      query: { searchPopup },
    },
    ui: { barIsVisible },
    search: {
      request: { slug, area },
      searchModalOptions: { activeTab: activeTabFromState, isVenueSearch },
    },
  } = getState();

  let searchBarCategory = type || slug;

  if ((searchPopup === 'true' || openState !== null) && isVenueSearch) {
    searchBarCategory = 'venue';
  }

  return {
    searchBarToggledStatus: openState === null ? barIsVisible : openState,
    searchBarCategory,
    searchBarSearchTerm: area,
    searchBarToggledMethod: method,
    searchBarSearchType: activeTab || activeTabFromState,
    previousPath,
  };
};

interface ISearchFiltersPropertiesProps {
  name: string;
  value: { [s: string]: number | string };
  tag: string | null;
  location: 'searchModal' | 'filtersModal' | 'searchResults';
  getState: () => IApplicationState;
}

/**
 * Outputs analytics searchFiltersPropertiesGeneric.
 */
const searchFiltersPropertiesGeneric = ({
  name,
  value,
  location,
}: ISearchFiltersPropertiesProps): ISearchFiltersPropertiesGeneric => {
  if (name === 'misc') {
    return {
      searchFiltersField: 'range',
      searchFiltersLocation: location || 'filtersModal',
      searchFiltersValue: Object.values(value)[0]?.toString(),
      searchFiltersSection: Object.keys(value)[0]?.toString(),
    };
  }

  return {
    searchFiltersField: Object.entries(value)
      .filter(([, value]) => !!value)
      .map(([key]) => key),
    searchFiltersLocation: location || 'filtersModal',
    searchFiltersValue: true,
    searchFiltersSection: name,
  };
};

export interface ISearchSuccessAnalyticsCustomData {
  inlineAdPresent?: boolean;
}

const getLoadedSearchResultsPropsFromSearchResponse = ({
  searchResponse,
}: {
  searchResponse: TSearchResponse;
}): ILoadedSearchResultsPropsFromSearchResponse => {
  const {
    filters,
    fields: {
      preset,
      swlat,
      swlon,
      nelat,
      nelon,
      area: searchTerm,
      page: searchPageNumber,
      type: searchSupplierCategory,
      sort: searchSortedBy,
      weddingWeekDays,
      weddingMonths,
      countryCode,
      radiusIncrease,
      capacityDining,
      accommodationRooms,
      hasLateAvailability,
    },
    results,
    totalResults: searchResultsTotal,
  } = searchResponse;

  const market = gazetteer.getMarketByCountry(countryCode);
  const loadedSearchResultsList = results.map(({ id }) => id);

  const loadedSearchResultsCoreScore = results
    .filter((item: IUISupplier) => loadedSearchResultsList.includes(item.id))
    .map(({ _score = 0 }) => _score);

  const searchCoords = { swlat, swlon, nelat, nelon };

  const searchResponseFilters = isNil(filters) || isEmpty(filters) ? undefined : filters;

  let searchActiveFilters = {
    ...searchResponseFilters,
  };

  // format priceSection with weddingWeekDays and weddingSeasons if they exist
  // getVariant_WeddingEstimatePriceFilter variant 1
  if (
    (!isNil(weddingWeekDays) && !isEmpty(weddingWeekDays)) ||
    (!isNil(weddingMonths) && !isEmpty(weddingMonths))
  ) {
    searchActiveFilters = {
      ...searchActiveFilters,
      priceSection: {
        weddingWeekDays: getWeddingDaysFilterFromResponse(weddingWeekDays),
        weddingSeasons: getWeddingSeasonFilterFromMonths({ weddingMonths, market }),
      },
    };
  }
  // otherwise use old format
  // getVariant_WeddingEstimatePriceFilter control
  else {
    if (
      searchActiveFilters.priceSection &&
      !searchActiveFilters.priceSection.weddingBudget &&
      !searchActiveFilters.priceSection.venueHirePrice
    ) {
      const priceBracket = Object.keys(searchActiveFilters.priceSection);
      searchActiveFilters = {
        ...searchActiveFilters,
        priceSection: { priceBracket },
      };
    }

    if (
      searchActiveFilters.priceSection?.weddingBudget ||
      searchActiveFilters.priceSection?.venueHirePrice
    ) {
      const priceSection = searchActiveFilters.priceSection;
      searchActiveFilters = {
        ...searchActiveFilters,
        priceSection,
      };
    }
  }

  if (radiusIncrease || capacityDining || accommodationRooms) {
    searchActiveFilters = {
      ...searchActiveFilters,
      misc: {
        ...searchActiveFilters.misc,
        radiusIncrease,
        capacityDining,
        accommodationRooms,
      },
    };
  }

  if (hasLateAvailability) {
    searchActiveFilters = {
      ...searchActiveFilters,
      specialOffers: {
        ...searchActiveFilters.specialOffers,
        hasLateAvailability,
      },
    };
  }

  const allActiveFilters = {
    ...searchActiveFilters,
    ...(preset ? { [preset]: { [preset]: true } } : {}),
  };

  return {
    searchSupplierCategory,
    searchTerm,
    searchCoords,
    searchActiveFilters: allActiveFilters,
    searchResultsLoaded: results.length,
    searchPageNumber,
    searchResultsTotal,
    searchSortedBy,
    loadedSearchResultsList,
    loadedSearchResultsCoreScore,
  };
};

const filterLabelProps = (props: { [k: string]: any }) => {
  const newProps = { ...props };
  delete newProps.loadedSearchResultsList;
  return newProps;
};

/*
 * This epic automatically sends an analytics event when the FETCH_SEARCH_SUCCESS action occurs and a 'track' is set in the action's payload.
 * */
export const sendAnalyticsOnFetchSearchSuccessEpic = (
  action$: Observable<Action>,
  deps: IEpicDeps,
) =>
  action$.pipe(
    ofType(SearchActionTypes.FETCH_SEARCH_SUCCESS),
    withLatestFrom(deps.state$),
    auditTime(1500),
    mergeMap(([action, state]) => {
      const payload = action.payload as IFetchSearchSuccessAction['payload'];
      const { track } = payload;
      return state.users.user && track
        ? of(
            fetchedSearchDataAnalytics({
              ...payload,
            }),
          )
        : of();
    }),
  );

export interface AnalyticsHelpersWindow extends Window {
  _cio?: {
    identify: (args: unknown) => void;
  };
}

declare let window: AnalyticsHelpersWindow;

export const fetchSearchSuggestionsAnalyticsEpic = (
  action$: Observable<Action>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(SearchActionTypes.FETCH_SEARCH_SUGGESTIONS_ANALYTICS_DEBOUNCED),
    withLatestFrom(state$),
    debounceTime(DEFAULT_DEBOUNCE_TIME),
    map(([{ payload }, state]: [Action, IApplicationState]) => {
      const pathname = state.app.pathname;
      const previousPath = state.app.previousPath;
      return {
        type: SearchActionTypes.FETCH_SEARCH_SUGGESTIONS_ANALYTICS,
        payload: {
          searchTerm: payload,
          previousPath,
          pathname,
        },
      };
    }),
  );

export default function searchAnalytics(
  action: Action,
  bridebookAnalytics: WebAnalyticsContext,
  getState: () => IApplicationState,
) {
  const { track, identifyWithTrack: identify } = bridebookAnalytics.getMethods(
    'Supplier search',
    filterLabelProps,
  );
  const { payload } = action;
  let eventHandled = true;

  const {
    app: { previousPath },
  } = getState();

  switch (action.type) {
    case SearchActionTypes.TOGGLE_SEARCH_BAR_ANALYTICS: {
      const { openState, searchBarToggledMethod } = payload;
      track({
        event: 'Toggled search bar',
        ...getSearchBarPropertiesGeneric(getState, false, openState, searchBarToggledMethod),
      });
      break;
    }
    case SearchActionTypes.TOGGLED_SEARCH_TYPE_ANALYTICS: {
      const { activeTab } = payload;
      track({
        event: 'Toggled search type',
        ...getSearchBarPropertiesGeneric(getState, '', null, null, activeTab),
      });
      break;
    }
    case SearchActionTypes.FETCH_SEARCH_SUGGESTIONS_ANALYTICS: {
      const isVenueConfirmationPopup = getState().venueConfirmation.showVenueConfirm;
      track({
        previousPath: payload.previousPath,
        event: 'Performed supplier search by name',
        searchedByNameTerm: payload.searchTerm,
        searchedByNameLocation: isVenueConfirmationPopup
          ? 'bookingConfirmationPopup'
          : getLocationName(getState(), payload.pathname),
      });
      break;
    }
    case SearchActionTypes.SEARCH_LOAD_MORE_ANALYTICS: {
      const { pageNumber: searchPageNumber } = payload;
      track({
        event: 'Clicked load more search results',
        searchPageNumber,
      });
      break;
    }

    case SearchActionTypes.FETCH_SEARCH_SUCCESS_ANALYTICS: {
      const state = getState();
      const {
        search: { searchSource, isMapView, onboardingFilters },
        users: { user },
        weddings: { profile: weddingProfile}
      } = state;
      const {
        customData,
        searchResponse: {
          results,
          fields: { countryCode, type, page, area: searchTerm },
        },
        searchResponse,
      } = action.payload as IFetchSearchSuccessAnalytics['payload'];

      // LIVE-21094: Check if the filters in search include the onboardingFilters
      const onboardingFiltersIncluded = Object.keys(onboardingFilters).every((key) => {
        if (searchResponse?.fields && Object.hasOwn(searchResponse.fields || {}, key)) {
          return (
            (searchResponse?.fields as Record<string, any>)[key] ===
            onboardingFilters[key].toString()
          );
        }
        if (priceFilterSeasonsArray.includes(<Season>key)) {
          const searchResponseSeasons = searchResponse?.fields.weddingMonths?.map(month => mapMonthToSeason(month))
          return searchResponseSeasons?.includes(key) && searchResponseSeasons.every(season => season && onboardingFilters[season])
        }

        if(priceFilterWeekDaysArray.includes(<PriceFilterWeekDay>key)){
          const weekDaysArray = priceFilterWeekDayMapping[<PriceFilterWeekDay>key]
          const searchResponseWeekDays = searchResponse?.fields.weddingWeekDays
          return weekDaysArray.every(weekDay => searchResponseWeekDays?.includes(weekDay)) &&
            searchResponseWeekDays?.every(searchResponseWeekDay => priceFilterWeekDayMapping.monWed.includes(searchResponseWeekDay)? onboardingFilters['monWed'] :onboardingFilters[searchResponseWeekDay])
        }
        return false;
      });

      const searchView = isMapView
        ? MapToggleStateAnalytics.MapView
        : MapToggleStateAnalytics.ListView;

      const determinedEventData = {
        type,
        countryName: Gazetteer.getCountryName(countryCode),
        countryCode,
        page,
      };

      const trackProps: ILoadedSearchResultsEventProps = {
        event: 'Loaded search results',
        ...getLoadedSearchResultsPropsFromSearchResponse({
          searchResponse,
        }),
        searchView,
        previousPath,
        inlineAdPresent: !!customData?.inlineAdPresent,
        searchSource: searchSource || 'newSearch',
        budgetInitialTarget: weddingProfile.budget,
        onboardingFilters: searchSource === 'finishedOnboarding' && onboardingFiltersIncluded,
        ...(determinedEventData
          ? {
              supplierCountryName: determinedEventData.countryName,
              supplierCountryCode: determinedEventData.countryCode,
            }
          : { supplierCountryName: 'N/A', supplierCountryCode: 'N/A' }),
      };
      if (
        user &&
        determinedEventData &&
        (determinedEventData.type === 'venue' || determinedEventData.type === 'photo')
      ) {
        const identifyProps = {
          [getIdentityPropsKey(determinedEventData.type)]: mapSupplierListToAnalytics(
            results,
            determinedEventData.type,
          ),
          searchTerm,
        };

        identify(identifyProps, trackProps, { userId: user?.id });

        if (typeof window?._cio !== 'undefined') {
          window._cio.identify({
            id: user.id,
            email: user?.contacts?.email,
          });
        }
      } else {
        track(trackProps);
      }
      break;
    }

    case VenuerexActionTypes.FETCH_VENUEREX_SUPPLIERS_SUCCESS: {
      const { supplierType, list } = payload as IFetchVenuerexSuppliersSuccessAction['payload'];

      const identityProps = {
        [getIdentityPropsKey(supplierType)]: mapSupplierListToAnalytics(list, supplierType),
      };

      identify(identityProps, undefined, { userId: getState().users.user?.id });
      break;
    }
    case SearchActionTypes.TOGGLED_SEARCH_FILTERS_ANALYTICS: {
      const {
        search: { filtersShown: searchToggledFiltersStatus },
      } = getState();

      const { method: searchToggledFiltersMethod } = payload;

      track({
        event: 'Toggled search filters',
        searchToggledFiltersMethod,
        searchToggledFiltersStatus,
        previousPath,
      } as IToggledSearchFiltersEventProps);
      break;
    }

    case SearchActionTypes.UPDATE_SEARCH_FILTERS_ANALYTICS: {
      track({
        event: 'Used search filters',
        ...searchFiltersPropertiesGeneric({ ...payload }),
        previousPath,
      } as IUsedSearchFiltersEventProps);
      break;
    }

    case SearchActionTypes.RESET_SEARCH_FILTERS_ANALYTICS:
      track({
        event: 'Reset search filters',
        previousPath,
      } as IResetSearchFiltersEventProps);
      break;

    case SearchActionTypes.SEARCH_SORT_CHANGE_ANALYTICS: {
      const { sortBy: searchSortedBy } = payload;
      track({
        event: 'Used sort by',
        searchSortedBy,
        previousPath,
      } as IUsedSortByEventProps);
      break;
    }

    case SearchActionTypes.MAP_MARKER_CLICKED_ANALYTICS: {
      const { type, id, name, publicId, town, county } = payload;
      track({
        event: 'User clicked on map pin',
        viewedSupplierCategory: type,
        viewedSupplierId: id,
        viewedSupplierName: name,
        viewedSupplierProfileURL:
          env.COUPLESIDE_URL +
          getSupplierUrl({
            type,
            publicId,
            id,
            name,
            town,
            county,
          }),
      });

      break;
    }

    case SearchActionTypes.INTERACTED_WITH_INJECTED_SNIPPET: {
      const { snippet } = payload;
      track({
        event: 'Interacted with injected snippets',
        snippetType: 'Enquiries',
        snippet,
      });
      break;
    }

    case SearchActionTypes.VIEWED_INJECTED_SNIPPET: {
      const { snippet, snippetType } = payload;
      track({
        event: 'Viewed injected snippets',
        snippetType,
        snippet,
      });
      break;
    }
    case SearchActionTypes.STARTED_QUIZ: {
      const { quizName, location } = payload;
      const previousPath = getState().app.previousPath;
      const venueBooked = getVenueBooked(getState());
      track({
        event: 'User started quiz',
        quizName,
        location,
        previousPath,
        venueBooked,
      });
      break;
    }
    case SearchActionTypes.NO_RESULTS_QUIZ: {
      const { quizName, searchSupplierCategory, location } = payload;
      const state = getState();
      const previousPath = state.app.previousPath;
      const collaborators = state.weddings.collaborators;
      const weddingId = state.weddings.profile.id;
      const userEmail = state.users.user?.contacts?.email;
      const countryCode = getCountryCode(state);
      const mobileApp = getIsMobile(state);
      track({
        event: 'Loaded no quiz match page',
        profileType: 'user',
        searchSupplierCategory,
        quizName,
        location,
        previousPath,
        countryCode,
        userEmail,
        weddingId,
        collaborators,
        mobileApp,
      });
      break;
    }
    case SearchActionTypes.CHOSE_QUIZ_FILTERS: {
      const { quizName, quizStepNumber, quizStepContent, filters } = payload;
      const { none, ...quizFilters } = formatQuizFilters(filters);
      const previousPath = getState().app.previousPath;

      track({
        event: 'User chose quiz filters',
        quizName,
        quizStepNumber,
        quizStepContent,
        quizFilters,
        previousPath,
      });
      break;
    }
    case SearchActionTypes.WENT_TO_NEXT_QUIZ_STEP: {
      const { quizName, quizStepNumber, quizStepContent, filters, navigationMethod } = payload;
      const { none, ...quizFilters } = formatQuizFilters(filters);
      const previousPath = getState().app.previousPath;

      track({
        event: 'User went to next quiz page',
        quizName,
        quizStepNumber,
        quizStepContent,
        quizFilters,
        navigationMethod,
        previousPath,
      });
      break;
    }
    case SearchActionTypes.REVEALED_QUIZ_MATCHES: {
      const { quizName, quizResultType, quizResult, filters } = payload;
      const { none, ...quizFilters } = formatQuizFilters(filters);
      const previousPath = getState().app.previousPath;

      track({
        event: 'User revealed quiz matches',
        quizName,
        quizResultType,
        quizResult,
        quizFilters,
        previousPath,
      });
      break;
    }
    case SearchActionTypes.EXITED_QUIZ: {
      const { quizName, quizStepNumber, quizStepContent } = payload;
      const previousPath = getState().app.previousPath;

      track({
        event: 'User exited quiz',
        quizName,
        quizStepNumber,
        quizStepContent,
        previousPath,
      });
      break;
    }
    case SearchActionTypes.LOADED_SEARCH_PREFERENCE_PAGE: {
      const { searchSupplierCategory } = payload;
      track({
        event: 'Loaded search preference page',
        searchSupplierCategory,
      });
      break;
    }
    case SearchActionTypes.LOADED_RECOMMENDED_SUPPLIER_PAGE: {
      track({
        event: 'Loaded recommended supplier page',
      });
      break;
    }
    case SearchActionTypes.CLICKED_FIND_MY_MATCH: {
      const { searchSupplierCategory } = payload;
      track({
        event: 'Clicked find my match',
        searchSupplierCategory,
      });
      break;
    }
    case SearchActionTypes.CHANGED_CATEGORY_SELECTION: {
      const { selectedSupplierCategory } = payload;
      track({
        event: 'Changed category selection',
        selectedSupplierCategory,
      });
      break;
    }
    case SearchActionTypes.CLICKED_SEARCH_MYSELF: {
      const { searchSupplierCategory } = payload;
      track({
        event: 'Clicked search myself',
        searchSupplierCategory,
      });
      break;
    }
    case SearchActionTypes.CLICKED_SHOW_ME_SUPPLIERS: {
      track({
        event: 'Clicked show me suppliers',
      });
      break;
    }
    case SearchActionTypes.USER_WENT_TO_A_PREVIOUS_QUIZ_PAGE: {
      const { quizName, quizStepNumber, quizStepContent } = payload;
      track({
        event: 'User went to a previous quiz page',
        quizName,
        quizStepNumber,
        quizStepContent,
      });
      break;
    }
    case SearchActionTypes.TAB_NAVIGATION_SUPPLIERS: {
      const { selectedTab } = payload as ISearchTriggerClickedSuppliersPageAnalytics['payload'];
      const state = getState();
      const userEmail = state.users.user?.contacts?.email;
      const previousPath = state.app.previousPath;
      const collaborator = getIsCollaborator(state);

      track({
        event: 'Used tab navigation on search landing page',
        category: 'Navigation',
        profileType: 'user',
        collaborator,
        previousPath,
        userEmail,
        selectedTab,
      });
      break;
    }
    // GROWTH FAKE DOOR ANALYTICS below - to be refactored one experiments proves successful
    case SearchActionTypes.VIEWED_BANNER_ANALYTICS: {
      const { bannerText, supplierCategory } = payload;
      const state = getState();
      const userEmail = state.users.user?.contacts?.email;
      const collaborator = getIsCollaborator(state);
      track({
        event: 'Viewed banner',
        ...genericProperties(state),
        bannerText,
        userEmail,
        collaborator,
        profileType: 'user',
        viewedLocation: 'search',
        searchSupplierCategory: supplierCategory,
      });
      break;
    }
    case SearchActionTypes.CLICKED_BANNER_ANALYTICS: {
      const state = getState();
      const userEmail = state.users.user?.contacts?.email;
      const collaborator = getIsCollaborator(state);
      const { supplierCategory } = payload;
      track({
        event: 'Clicked on banner',
        ...genericProperties(state),
        userEmail,
        collaborator,
        profileType: 'user',
        viewedLocation: 'search',
        searchSupplierCategory: supplierCategory,
      });
      break;
    }
    case SearchActionTypes.CLICKED_REGISTER_INTEREST_BANNER_ANALYTICS: {
      const state = getState();
      const userEmail = state.users.user?.contacts?.email;
      const collaborator = getIsCollaborator(state);
      track({
        event: 'Clicked register interest on banner popup',
        ...genericProperties(state),
        userEmail,
        collaborator,
        profileType: 'user',
        viewedLocation: 'search',
        searchSupplierCategory: 'beauty',
      });
      break;
    }

    case SearchActionTypes.INTERACTED_WITH_AD_ANALYTICS: {
      const { track } = bridebookAnalytics.getMethods('advertising');
      const {
        actionType,
        adLocation,
        id: adId,
        position: adPosition,
        size: adSize,
        category: adCategory,
        supplier: adSupplierId,
      } = payload;

      track({
        event: 'Interacted with ad',
        actionType,
        adLocation,
        adId,
        adPosition,
        adSize,
        adCategory,
        adSupplierId,
      });
      break;
    }

    default:
      eventHandled = false;
      break;
  }

  if (!eventHandled) {
    const typedAction = action as
      | IChangedSearchCategoryAnalytics
      | IChangedSearchLocationAnalytics
      | IClickedOnFilteredSearchTileAnalytics;

    const state = getState();
    const searchLocation = state.search.searchLocation;
    if (searchLocation.status === 'initialized') {
      const searchTerm = searchLocation.selected.searchText;
      switch (typedAction.type) {
        case SearchActionTypes.CHANGED_SEARCH_CATEGORY_ANALYTICS:
          track({
            ...genericProperties(state),
            event: 'Changed search category',
            category: 'Supplier search',
            searchTerm,
            ...typedAction.payload,
            searchCategoryChangeSource: getSearchChangeSource(),
          });
          break;
        case SearchActionTypes.CHANGED_SEARCH_LOCATION_ANALYTICS:
          track({
            ...genericProperties(state),
            event: 'Changed search location',
            category: 'Supplier search',
            ...typedAction.payload,
            noOfLettersEntered:
              typedAction.payload.notAutocompletedSearchTerm &&
              typedAction.payload.notAutocompletedSearchTerm !== typedAction.payload.newSearchTerm
                ? typedAction.payload.notAutocompletedSearchTerm.length
                : undefined,
            searchLocationChangeSource:
              typedAction.payload.searchLocationChangeSource || getSearchChangeSource(),
          });
          break;
        case SearchActionTypes.CLICKED_ON_FILTERED_SEARCH_TILE_ANALYTICS:
          track({
            ...genericProperties(state),
            event: 'Clicked on filtered search tile',
            category: 'Supplier search',
            searchTerm,
            searchSupplierCategory:
              typedAction.payload.searchTileFilterCategory === 'supplierType'
                ? typedAction.payload.searchTileFilterValue
                : 'venue',
            ...typedAction.payload,
          });
          break;
      }
    }
  }
}

export const changedSearchLocationAnalyticsDebouncedEpic = (
  action$: Observable<
    IChangedSearchLocationAnalyticsDebounced | ISetSearchLocationAutocompleteText
  >,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(
      SearchActionTypes.CHANGED_SEARCH_LOCATION_ANALYTICS_DEBOUNCED,
      SearchActionTypes.SET_SEARCH_LOCATION_AUTOCOMPLETE_TEXT,
    ),
    withLatestFrom(state$),
    debounceTime(DEFAULT_DEBOUNCE_TIME),
    mergeMap(([action, state]) => {
      if (action.type === SearchActionTypes.SET_SEARCH_LOCATION_AUTOCOMPLETE_TEXT) {
        const {
          payload: { searchText },
        } = action;
        const search: ISearchState = state.search;
        const searchLocation = search.searchLocation;
        if (searchLocation.status !== 'initialized' || !search.request.slug) return of();
        const category = search.request.slug;
        return of(
          changedSearchLocationAnalyticsDebounced({
            newSearchTerm: searchText,
            previousSearchTerm: searchLocation.selected.searchText,
            searchSupplierCategory: category,
          }),
        );
      } else if (action.type === SearchActionTypes.CHANGED_SEARCH_LOCATION_ANALYTICS_DEBOUNCED) {
        const payload = action.payload;
        return of(changedSearchLocationAnalytics(payload));
      } else {
        return of();
      }
    }),
  );
const getSearchChangeSource = () => {
  const pathname = Router.pathname;
  if (pathname === PublicUrls.searchLanding) {
    return 'searchLandingPage';
  } else if (pathname === ProtectedUrls.home) {
    return 'home';
  } else {
    return 'searchResults';
  }
};

const genericProperties = (state: IApplicationState) => {
  const previousPath = state.app.previousPath;
  return {
    previousPath,
  };
};
