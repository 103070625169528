import { isEmpty } from 'ramda';
import { Epic } from 'redux-observable';
import { abTestingReducer } from '@bridebook/toolbox/src/ab-testing/';
import {
  Action,
  AnyAction,
  EnhancedStore,
  Middleware,
  Reducer,
  ReducersMapObject,
  combineReducers,
  configureStore,
} from '@reduxjs/toolkit';
import { HYDRATE } from 'app-shared/lib/next-redux-wrapper';
import { WebAnalyticsContext } from 'lib/bbcommon/utils/bridebook-analytics';
import { modulesSlice } from 'lib/modules';
import newonboarding from 'lib/onboarding-new/slice';
import quiz from 'lib/quiz/slice';
import task from 'lib/task/slice';
import { IApplicationState } from 'lib/types';
import acl from './access-control/reducer';
import AnalyticsMiddleware from './analyticsMiddleware';
import app from './app/reducer';
import articles from './articles/reducer';
import auth from './auth/reducer';
import bbcommon from './bbcommon/reducer';
import budget from './budget/reducer';
import checklist from './checklist/reducer';
import createReduxMiddleware from './create-redux-middleware';
import datepicker from './datepicker/reducer';
import enquiries from './enquiries/reducer';
import { env } from './env';
import epics from './epics/reducer';
import files from './files/reducer';
import guestlist from './guestlist/reducer';
import mobileapp from './mobile-app/reducer';
import { IModuleKey } from './modules/types';
import realWeddings from './real-weddings/slice';
import scrapbook from './scrapbook/reducer';
import search from './search/reducer';
import settings from './settings/reducer';
import shortlist from './shortlist/reducer';
import supplier from './supplier/reducer';
import ui from './ui/reducer';
import users from './users/reducer';
import venueConfirmation from './venue-confirmation/reducer';
import venuerex from './venuerex/reducer';
import weddings from './weddings/reducer';

const analytics = null;

const staticReducers: ReducersMapObject = {
  abTesting: abTestingReducer,
  acl,
  app,
  articles,
  auth,
  bbcommon,
  budget,
  checklist,
  datepicker,
  enquiries,
  epics,
  files,
  guestlist,
  mobileapp,
  modules: modulesSlice.reducer,
  scrapbook,
  newonboarding,
  search,
  settings,
  shortlist,
  supplier,
  ui,
  users,
  task,
  venueConfirmation,
  venuerex,
  weddings,
  realWeddings,
  quiz,
};

const hydrateReducer = (state: IApplicationState, action: AnyAction) => {
  const clientState: IApplicationState = state;
  const serverState: IApplicationState = action.payload;
  const nextState = {
    ...clientState, // use previous state
    ...serverState, // apply delta from hydration
  };

  if (clientState.app.deviceServerSet) {
    nextState.app.device.isCordova = clientState.app.device.isCordova;
    nextState.app.device.isChrome = clientState.app.device.isChrome;
    nextState.app.device.sessionId = clientState.app.device.sessionId;
    nextState.app.device.versionMajor = clientState.app.device.versionMajor;
    nextState.app.deviceServerSet = clientState.app.deviceServerSet;
  }

  if (clientState.app.deviceClientSet) {
    nextState.app.device.width = clientState.app.device.width;
    nextState.app.device.height = clientState.app.device.height;
    nextState.app.device.isMobile = clientState.app?.device.isMobile;
    nextState.app.device.isTablet = clientState.app?.device.isTablet;
    nextState.app.device.isLandscape = clientState.app.device.isLandscape;
    nextState.app.device.isDesktopDesign = clientState.app.device.isDesktopDesign;
    nextState.app.device.isDesktop = clientState.app?.device.isDesktop;
    nextState.app.deviceClientSet = clientState.app?.deviceClientSet;
  }

  if (clientState.app.device.serverCountryCode) {
    nextState.app.device.serverCountryCode = clientState.app.device.serverCountryCode;
  }

  if (clientState.app.previousSearch) {
    nextState.app.previousSearch = clientState.app.previousSearch;
  }
  if (clientState.auth.form.disabled) {
    nextState.auth.form.disabled = clientState.auth.form.disabled;
  }

  if (clientState.auth?.form?.fields?.countryCode) {
    nextState.auth.form.fields.countryCode = clientState.auth.form.fields.countryCode;
  }
  if (clientState.auth?.form?.fields?.email) {
    nextState.auth.form.fields.email = clientState.auth.form.fields.email;
  }

  if (clientState.app?.started) nextState.app.started = clientState.app.started;
  if (clientState.app?.groups) nextState.app.groups = clientState.app.groups;
  if (clientState.app?.groupsLoaded) nextState.app.groupsLoaded = clientState.app.groupsLoaded;

  if (clientState.app?.navigationJourneyStartPath) {
    nextState.app.navigationJourneyStartPath = clientState.app.navigationJourneyStartPath;
  }

  if (clientState.app?.pathname) {
    nextState.app.pathname = clientState.app.pathname;
  }
  if (clientState.app?.startedMainNavigationCounter) {
    nextState.app.startedMainNavigationCounter = clientState.app.startedMainNavigationCounter;
  }

  if (clientState.app?.pilingCounter) {
    nextState.app.pilingCounter = clientState.app.pilingCounter;
  }

  if (clientState.app?.previousPath) {
    nextState.app.previousPath = clientState.app.previousPath;
  }
  if (!isEmpty(clientState.app.previousSearchQuery)) {
    nextState.app.previousSearchQuery = clientState.app.previousSearchQuery;
  }
  if (!isEmpty(clientState.app.query)) {
    nextState.app.query = clientState.app.query;
  }
  // if (!isEmpty(state.app.previousQuery)) {
  //   nextState.app.previousQuery = state.app.previousQuery;
  // }

  if (clientState.app?.serverPage) {
    nextState.app.serverPage = clientState.app.serverPage;
  }

  if (clientState.users?.user) {
    nextState.users.user = clientState.users.user;
    nextState.users.providers = clientState.users.providers;
  } // preserve user on client side navigation
  // if (state.users?.providers) nextState.users.providers = state.users.providers;
  if (clientState.users.partnerNamesForm?.partners.length) {
    nextState.users.partnerNamesForm.partners = clientState.users.partnerNamesForm.partners;
  } // preserve user on client side navigation
  if (clientState.weddings.loaded) nextState.weddings = clientState.weddings;

  if (clientState.checklist.loaded) nextState.checklist = { ...clientState.checklist };
  if (clientState.checklist.tasksInitial)
    nextState.checklist = {
      ...nextState.checklist,
      tasksInitial: { ...clientState.checklist.tasksInitial },
    };
  if (clientState.task.taskFlows.status === 'loaded')
    nextState.task = { ...nextState.task, taskFlows: clientState.task.taskFlows };
  if (clientState.task.todayTasks.status === 'loaded')
    nextState.task = { ...nextState.task, todayTasks: clientState.task.todayTasks };

  if (clientState.task.todayTaskFlowDefinition.status === 'loaded')
    nextState.task = {
      ...nextState.task,
      todayTaskFlowDefinition: clientState.task.todayTaskFlowDefinition,
    };

  if (clientState.mobileapp.loaded) nextState.mobileapp = clientState.mobileapp;
  if (clientState.mobileapp.platform) {
    nextState.mobileapp = { ...nextState.mobileapp, platform: clientState.mobileapp.platform };
  }

  if (clientState.epics.epicsAdded.guestlist) {
    nextState.epics.epicsAdded.guestlist = clientState.epics.epicsAdded.guestlist;
  }
  if (clientState.epics.epicsAdded.scrapbook) {
    nextState.epics.epicsAdded.scrapbook = clientState.epics.epicsAdded.scrapbook;
  }
  if (clientState.epics.epicsAdded.budget) {
    nextState.epics.epicsAdded.budget = clientState.epics.epicsAdded.budget;
  }
  if (clientState.epics.epicsAdded.checklist) {
    nextState.epics.epicsAdded.checklist = clientState.epics.epicsAdded.checklist;
  }
  if (clientState.epics.epicsAdded.supplier) {
    nextState.epics.epicsAdded.supplier = clientState.epics.epicsAdded.supplier;
  }
  if (clientState.epics.epicsAdded.article) {
    nextState.epics.epicsAdded.article = clientState.epics.epicsAdded.article;
  }

  if (clientState.enquiries.enquiryForm.fields.email) {
    nextState.enquiries.enquiryForm = clientState.enquiries.enquiryForm;
  }

  if (!isEmpty(clientState.enquiries.enquiryDates)) {
    nextState.enquiries.enquiryDates = clientState.enquiries.enquiryDates;
  }

  nextState.enquiries = {
    ...nextState.enquiries,
    enquiryCount: clientState.enquiries.enquiryCount,
  };

  if (clientState.datepicker) {
    nextState.datepicker = clientState.datepicker;
  }

  if (clientState.modules) {
    nextState.modules = clientState.modules;
  }

  nextState.search = {
    ...nextState.search,
    request: { ...nextState.search.request, slug: clientState.search.request.slug },
    searchSource: clientState.search.searchSource,
    searchLocation: clientState.search.searchLocation,
  };

  if (clientState.search.loaded) {
    nextState.search = { ...clientState.search };
  }

  if (clientState.guestlist.loaded) {
    nextState.guestlist = clientState.guestlist;
  }

  if (clientState.shortlist.loaded) {
    nextState.shortlist = { ...clientState.shortlist };
  }

  if (clientState.auth?.collaboratorInvite) {
    nextState.auth = { ...nextState.auth, collaboratorInvite: clientState.auth.collaboratorInvite };
  }

  if (clientState.budget.listenerInitialised) {
    nextState.budget = { ...clientState.budget };
  }

  nextState.venueConfirmation = { ...clientState.venueConfirmation };
  nextState.bbcommon = { ...clientState.bbcommon };
  nextState.bbcommon = { ...clientState.bbcommon };
  nextState.abTesting = { ...clientState.abTesting };
  nextState.ui.achievements = { ...clientState.ui.achievements };
  nextState.acl = { ...clientState.acl };
  if (clientState.ui?.badgesLoaded) nextState.ui.badgesLoaded = clientState.ui.badgesLoaded;

  // Comparing publicId here because that's what we can set from query in getServerSideProps on profile
  if (
    nextState.supplier.supplier?.data?.publicId === clientState.supplier.supplier?.data?.publicId
  ) {
    nextState.supplier = clientState.supplier;
  }

  return nextState;
};

export function createReducerManager(initialReducers: ReducersMapObject<IApplicationState>) {
  const reducers: ReducersMapObject<IApplicationState> = { ...initialReducers };

  let combinedReducer = combineReducers<IApplicationState>(reducers);

  // An array which is used to delete state keys when reducers are removed
  let modulesToRemove: IModuleKey[] = [];

  const reduce: Reducer<IApplicationState, AnyAction> = (state, action) => {
    // If any reducers have been removed, clean up their state first
    if (state && modulesToRemove.length > 0) {
      state = { ...state };
      for (const key of modulesToRemove) {
        delete state[key];
      }
      modulesToRemove = [];
    }

    if (action.type === HYDRATE) {
      return hydrateReducer(state as IApplicationState, action);
    } else {
      // Delegate to the combined reducer
      return combinedReducer(state, action);
    }
  };
  return {
    getReducerMap: () => reducers,

    reduce,

    // Adds a new reducer with the specified key
    add: (key: keyof IApplicationState, reducer: Reducer) => {
      if (!key || reducers[key]) {
        return;
      }

      // Add the reducer to the reducer mapping
      reducers[key] = reducer;

      // Generate a new combined reducer
      combinedReducer = combineReducers<IApplicationState>(reducers);
    },

    // Removes a reducer with the specified key
    remove: (key: keyof IApplicationState) => {
      if (!key || !reducers[key]) {
        return;
      }

      // Remove it from the reducer mapping
      delete reducers[key];

      // Add the key to the list of keys to clean up
      modulesToRemove.push(key);

      // Generate a new combined reducer
      combinedReducer = combineReducers<IApplicationState>(reducers);
    },
  };
}

const createReduxStore = (initialState: Partial<IApplicationState>) => {
  const state = initialState;
  const sessionId = state?.app?.device?.sessionId as string;
  const isCordova = state?.app?.device?.isCordova;

  const bridebookAnalytics = new WebAnalyticsContext(analytics, {
    mobileApp: isCordova,
    sessionId,
    appVersion: env.PLATFORM_VERSION,
  });
  const analyticsMiddleware = AnalyticsMiddleware(bridebookAnalytics);

  const { middleware, rootEpic, epicMiddleware } = createReduxMiddleware([
    analyticsMiddleware as unknown as Middleware,
  ]);

  const reducerManager = createReducerManager(staticReducers);
  const store = configureStore({
    reducer: reducerManager.reduce,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: false,
        serializableCheck: false,
        immutableCheck: {
          // TODO fixme state mutation
          ignoredPaths: ['datepicker.instances.weddingDate', 'abTesting', 'ui.achievements'],
        },
      }),
    enhancers: [middleware],
    devTools: {
      name: 'Bridebook App',
    },
  }) as IStore;

  store.reducerManager = reducerManager;

  if (process.browser) {
    epicMiddleware?.run?.(rootEpic as Epic<Action<any>>);
    return { store, rootEpic };
  } else {
    return { store };
  }
};

export type IStore = EnhancedStore<IApplicationState> & {
  reducerManager: ReturnType<typeof createReducerManager>;
};

export default createReduxStore;
